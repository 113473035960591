import type { GetServerSideProps } from 'next/types';
import dynamic from 'next/dynamic';
import { extractTags, findSubsidyTag } from '@lambda/reebelo/src/tagHelpers';
import Image from 'next/future/image';
import Head from 'next/head';
import {
  Image as ProductImage,
  Product,
  ProductVariant,
} from '@lambda/commons/apis/shopify/types/storefront';
import ElasticSearch from '@lambda/commons/apis/elasticsearch';
import {
  ItemIndexKey,
  SearchOffers,
} from '@lambda/commons/apis/elasticsearch/types';
import * as ShopifyUtils from '@lambda/commons/apis/shopify/utils';
import cn from 'classnames';
import React, { ReactNode, useState, useEffect, useContext } from 'react';
import { AppCtx } from 'pages/_app';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import ProductApi from '@lambda/apis/src/product';
import Redis from '@lambda/apis/src/redis/Redis';
import { RC_DURATION } from '@lambda/reebelo';
import CategoryApi from '@lambda/apis/src/category';
import { ProductCategoryWarranty } from '@lambda/apis/src/category/types';
import { WarrantyT } from '@lambda/apis/src/warranty/types';
import shopify from '@/lib/shopify';
import { formatOfferUri, formatOfferTitle } from '@/lib/offer';
import settings from '@/settings';
import GTM from '@/lib/google-tag-manager';
import infoIconBlack from '@/public/icons/product/infoIconBlack.svg';
import i18n from '@/settings/i18n';
import {
  ReplacedProduct,
  selectWarranty,
} from '@/lib/collections/catalogue/helpers';
import {
  ConditionPopup,
  CONDITIONS,
} from '@/components/products/conditionPopup/ConditionPopup';
import AdditionalItem from '@/components/commons/AdditionalItem';
import ProductImageSlider from '@/components/products/ProductImageSlider';
import PurchaseLinks from '@/components/products/PurchaseLinks';
import Microdata from '@/components/products/Microdata';
import TrustpilotWidget from '@/components/products/TrustpilotWidget';
import sellingFast from '@/public/icons/product/sellingFast.svg';
import heart from '@/public/icons/product/heart.svg';
import ShippingAndOtherOffer from '@/components/collections/[handle]/ShippingAndOtherOffer';
import useVendorDetails, { useVendorDispatch } from '@/lib/use-vendor-details';
import useProductSoldCount from '@/lib/use-product-sold-count';
import ProductSold from '@/components/collections/[handle]/ProductSold';
import useDeliveryDate from '@/lib/use-delivery-date';
import useExpressShippingOffer from '@/lib/use-express-shipping-offer';
import { ShippingData } from '@/components/collections/[handle]/NewProductPage/types';
import getWarrantyEligiblity from '@/lib/backend/warranty';
import { NON_TECH_CATEGORIES } from '@/settings/configs';
import { computeComparePrice } from '@/lib/use-pim-compare-price';
import PromoMessages from '@/components/collections/[handle]/NewProductPage/PromoMessages';
import SpecificationsNew from '@/components/collections/[handle]/NewProductPage/SpecificationsNew';
import { extractIsDealFromTags } from '@/lib/hot-deals/helpers';
import { WebsiteSchema } from '@/components/homepage/WebsiteSchema';
import useProductReviews from '@/lib/use-product-reviews';
import findPimProduct from '@/lib/backend/findPimProduct';
import { useRecentlyViewed } from '@/lib/use-recently-viewed';
import { trackViewed } from '@/lib/analytics/trackProduct';
import MainPriceInfo from '@/components/products/MainPriceInfo';
import SecondaryPriceInfo from '@/components/products/SecondaryPriceInfo';
import fetchDealViewsCount from '@/lib/use-product-deal-view-count';
import { GoogleTopQualityBadge } from '@/lib/use-google-badge';
import {
  streamlineBreadcrumb,
  generateOldProductSpecifications,
} from '@/components/collections/[handle]/NewProductPage/helpers';
import VendorInfoSection from '@/components/collections/[handle]/NewProductPage/VendorInfoSection';
import {
  checkIsTradeInEligible,
  shouldShowPromoMessage,
  streamLineCondition,
} from '@/lib/collections/helpers';
import { REDIRECT_HOME } from '@/lib/common';
import TradeInSection from '@/components/collections/[handle]/NewProductPage/TradeIn/TradeInSection';
import TradeInModal from '@/components/collections/[handle]/NewProductPage/TradeIn/TradeInModal';
import CompactTradeIn from '@/components/collections/[handle]/NewProductPage/TradeIn/CompactTradeIn';
import PopularCategories from '@/components/commons/PopularCategories';
import Breadcrumb from '../../components/collections-v2/common/Breadcrumb';
import { useCustomerContext } from '@/lib/customer/CustomerContext';

const Wishlist = dynamic(() => import('@/components/products/Wishlist'), {
  ssr: false,
});

const RecentlyViewed = dynamic(
  () => import('@/components/homepage/RecentlyViewed'),
  { ssr: false },
);
const Usps = dynamic(() => import('@/components/collections/[handle]/Usps'));
const HotDealBadge = dynamic(
  () => import('@/components/collections/hotDeals/HotDealBadge'),
);
const MoreProductInfo = dynamic(
  () => import('@/components/collections/[handle]/MoreProductInfo'),
);
const ProductInfoBox = dynamic(
  () =>
    import('@/components/collections/[handle]/NewProductPage/ProductInfoBox'),
  {
    ssr: false,
  },
);
const WarrantySlideOver = dynamic(
  () => import('@/components/collections/[handle]/WarrantySlideOver'),
  { loading: () => null },
);

const Marketplace = dynamic(() => import('@/components/commons/Marketplace'), {
  ssr: false,
});
const CircularEconomy = dynamic(
  () => import('@/components/homepage/CircularEconomy'),
  {
    ssr: false,
  },
);
const HotDealSlider = dynamic(
  () => import('@/components/homepage/HotDealSlider'),
  { ssr: false },
);
const YouMayAlsoLike = dynamic(
  () => import('@/components/collections-v2/common/YouMayAlsoLike'),
  { ssr: false },
);
const MoreFromBrand = dynamic(
  () => import('@/components/products/MoreFromBrand'),
  {
    ssr: false,
  },
);
const BackToTopButton = dynamic(
  () => import('@/components/collections/BackToTopButton'),
  { ssr: false },
);
const ProductReviews = dynamic(
  () => import('@/components/collections/reviews/ProductReviews'),
  { ssr: false },
);
const ReviewsStars = dynamic(
  () => import('@/components/collections/reviews/ReviewsStars'),
  {
    ssr: false,
  },
);

const t = i18n.init();

const REDIS_KEY_OFFER_HANDLE_MAPPING = 'offer-handle-mapping';
const redis = new Redis(REDIS_KEY_OFFER_HANDLE_MAPPING);

const Section = (props: {
  children?: ReactNode;
  className?: string;
  id?: string;
}) => (
  <div
    className={props.className ? props.className : 'py-10'}
    id={props.id ?? ''}
  >
    {props.children}
  </div>
);

type Props = {
  product: Product;
  variant: ProductVariant;
  images: ProductImage[];
  price: number;
  isWarrantyEligible: boolean;
  isOrganicAdRedirect: boolean;
  psku: string;
  productDescription: string;
  productFAQ: string;
  productSpecifications: { key: string; value: string }[];
  offer?: SearchOffers;
  categoryWarrantyInfo?: ProductCategoryWarranty | null;
  categoryPk: string;
  categoryParentName: string | null;
  breadcrumb: { name: string; pk: string }[];
};

type Path = { handle: string };

const EsOffer = new ElasticSearch(ItemIndexKey.SEARCH_OFFERS, {
  store: settings.store,
});

const categoryApi = new CategoryApi();

const getOfferByHandle = async (handle: string): Promise<SearchOffers[]> => {
  const { sources } = await EsOffer.searchSources({
    size: 1,
    query: {
      bool: {
        must: [{ term: { store: settings.store } }, { term: { handle } }],
      },
    },
  });

  return sources;
};

const redirectToCollection = (productHandle: string, resolvedUrl: string) => {
  const params = resolvedUrl.split('?');

  params.shift();

  const uniqueParams: Record<string, string> = {};

  params.forEach((param) => {
    param.split('&').forEach((subParam) => {
      const [key, value] = subParam.split('=');

      if (key && key.length > 0 && value && value.length > 0)
        uniqueParams[key] = value;
    });
  });

  const purifyParams: string[] = [];

  Object.keys(uniqueParams).forEach((key) => {
    purifyParams.push(`${key}=${uniqueParams[key]}`);
  });

  return {
    redirect: {
      permanent: true,
      destination: `/collections/${productHandle}?${purifyParams.join('&')}`,
    },
  };
};

export const getServerSideProps: GetServerSideProps<Props, Path> = async ({
  params,
  resolvedUrl,
}) => {
  const redirectRoute = () => ({
    redirect: { permanent: false, destination: '/' },
  });

  if (params == null) return redirectRoute();

  let productHandle = params.handle;

  // It is weird, but it is the solution to handle the bad url sent to Google feed
  // ex: https://reebelo.com/products/apple-i-phone-11-pro-max-64-gb-midnight-green-fully-unlocked-premium-y3l1a&utm_organicad=true
  if (
    /&utm_organicad=true$/.test(productHandle) &&
    !resolvedUrl.includes('?')
  ) {
    productHandle = productHandle.replace('&utm_organicad=true', '');
    // eslint-disable-next-line no-param-reassign
    resolvedUrl = `/products/${productHandle}?utm_organicad=true`;
  }

  const productHandleRedirect = await redis.get(productHandle);

  if (productHandleRedirect) {
    try {
      const { handle, url } = JSON.parse(productHandleRedirect);

      return redirectToCollection(
        handle,
        `${url}${url.includes('?') ? '&' : '?'}${resolvedUrl.split('?').pop()}`,
      );
    } catch (error) {
      redis.del(productHandle);
    }
  }

  const response = await shopify.fetch({
    query: `query ($handle: String!) {
              product(handle: $handle) {
                id tags title vendor description descriptionHtml totalInventory productType handle
                images(first:10){ edges { node { url } } }
                variants(first:1){ edges { node {
                  id availableForSale quantityAvailable barcode
                  priceV2 { amount }
                  compareAtPriceV2 { amount }
                  sku
                } } }
              }
            }`,
    variables: { handle: productHandle },
    skipLogging: true,
  });
  const { product } = response;

  const productApi = new ProductApi();
  let productData = null;

  if (product == null) {
    productData = await productApi.fetchProductByHandle(productHandle);

    if (productData && productApi.isNewProduct(productData)) {
      await redis.set(
        productHandle,
        JSON.stringify({ handle: productData.handle, url: resolvedUrl }),
        'EX',
        24 * 3600,
      );

      return redirectToCollection(productData.handle, resolvedUrl);
    }

    return redirectRoute();
  }
  const isWarrantyItem = product.title.toLowerCase().includes('reebelocare');
  const isExpressShippingItem = product.title
    .toLowerCase()
    .includes('express shipping');

  if (isWarrantyItem || isExpressShippingItem) return REDIRECT_HOME;

  // Handle Description Logic
  const psku = extractTags(product.tags)?.psku;

  const offerUri = formatOfferUri(productHandle, product.tags);
  const query = offerUri.query as Record<string, string>;
  const queryParams = query
    ? Object.keys(query)
        .filter((key) => key !== 'handle')
        .map((key) => `${key}=${encodeURIComponent(query[key])}`)
        .join('&')
    : '';

  if (psku) {
    productData = await productApi.fetchProductByPsku(psku);

    if (productData && productApi.isNewProduct(productData)) {
      const redirectUrl = `${resolvedUrl}${
        resolvedUrl.includes('?') ? '&' : '?'
      }${queryParams}`;

      await redis.set(
        params.handle,
        JSON.stringify({ handle: productData.handle, url: redirectUrl }),
        'EX',
        24 * 3600,
      );

      return redirectToCollection(productData.handle, redirectUrl);
    }
  }

  const [esOffer] = await getOfferByHandle(params.handle);
  const { category } = extractTags(product.tags);
  const mappedCategories = await categoryApi.fetchMappedCategory([
    category ?? '',
  ]);
  const categoryPk =
    esOffer?.categoryPks?.[0] ?? mappedCategories?.categoryPks?.[0] ?? '';

  const breadcrumb = streamlineBreadcrumb(
    await categoryApi.fetchBreadcrumb(categoryPk),
    categoryPk,
  );

  const categoryParentName = await categoryApi.fetchParentCategoryName(
    categoryPk,
  );

  const categoryWarrantyInfo = await categoryApi.fetchWarrantyInfoByCategoryPk(
    categoryPk,
    settings.store,
  );
  const pimProduct = psku ? await findPimProduct(psku) : undefined;
  const productDescription = pimProduct?.description ?? '';
  const productFAQ = pimProduct?.faq ?? '';
  const productSpecifications = pimProduct?.specifications ?? [];

  if (offerUri.pathname && !offerUri.pathname.startsWith('/products')) {
    const destination = `${offerUri.pathname.replace(
      '[handle]',
      query.handle,
    )}?${queryParams}`;

    return {
      redirect: { permanent: true, destination },
    };
  }

  const variant = product.variants.edges?.[0]?.node;

  if (variant == null) return redirectRoute();

  const isWarrantyEligible =
    esOffer == null ? false : await getWarrantyEligiblity(esOffer.psku);
  const isOrganicAdRedirect = !!resolvedUrl?.includes('utm_organicad');
  const props = {
    product,
    variant,
    images: product.images.edges.map((e) => e.node),
    price:
      esOffer?.price ?? product.variants.edges?.[0]?.node?.priceV2?.amount ?? 0,
    isWarrantyEligible,
    isOrganicAdRedirect,
    psku: esOffer?.psku ?? '',
    productDescription,
    productFAQ,
    productSpecifications,
    offer: esOffer ?? null,
    categoryWarrantyInfo,
    categoryPk,
    mappedCategories,
    categoryParentName,
    breadcrumb,
  };

  return { props };
};

const PAUSE_TAG = 'product_is_paused';

export const replacePlusSymbol = (str: string) => str.replace('+', ' Plus');

const removeDefaultColor = (str: string) => str.replace('- Default -', '-');

export default function ProductPage(props: Props) {
  const {
    product,
    variant,
    images,
    price,
    isWarrantyEligible,
    isOrganicAdRedirect,
    psku,
    productDescription,
    productFAQ,
    offer,
    categoryParentName,
    breadcrumb,
  } = props;

  const { customer } = useCustomerContext();
  const tags = extractTags(product.tags);
  const isPaused = product.tags.includes(PAUSE_TAG);
  const [warrantyID, setWarrantyID] = useState<null | number>(null);
  const [showTradeInModal, setShowTradeInModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [productWarranty, setProductWarranty] = useState<
    WarrantyT | undefined
  >();
  const { productType, title } = product;
  const { rsku, storage, condition } = tags;

  const category = offer?.categories?.[0] ?? tags.category;
  const brand = offer?.brand ?? tags.brand;

  const comparePrice = computeComparePrice({
    matchedComparePrice: Number(variant.compareAtPriceV2?.amount),
    tags: product.tags,
    actualPrice: price,
  });

  const gtmProduct = {
    id: variant.id,
    name: replacePlusSymbol(title),
    price: price.toString(),
    color: tags.color,
    variant: rsku || '',
    vendor: product.vendor,
    condition,
    brand: brand || title.split(' ')[0],
    category: category || productType || '',
    comparePrice: Number.isNaN(comparePrice) ? 0 : comparePrice,
    psku,
    storage,
    images,
  };

  useEffect(() => {
    const eventPayload = {
      detail: {
        actionField: { list: `${productType} list` },
        products: [{ ...gtmProduct }],
      },
    };

    GTM.event(null, eventPayload);
  }, []);

  useEffect(() => {
    trackViewed({
      offer,
      customer,
      properties: {
        psku: gtmProduct.psku,
        category: gtmProduct.category,
        price: Number(gtmProduct.price),
        compare_price: gtmProduct.comparePrice,
        color: gtmProduct.color,
        condition: gtmProduct.condition,
      },
    });
  }, [customer]);

  const productTitle = formatOfferTitle(
    product.title.replace(/Oppo/g, 'OPPO'),
    tags,
  );
  const descriptionPrefix = [
    { field: 'Storage', property: tags.storage },
    { field: 'Color', property: tags.color },
    { field: 'Condition', property: condition },
  ]
    .filter((data) => data.property != null && data.property !== 'Default')
    .map((data) => `${data.field}: ${data.property}`)
    .join(', ');

  const handleWarrantyClick = () => {
    if (productWarranty == null || !productWarranty.shopifyVariantId) return;

    if (warrantyID !== null) setWarrantyID(null);
    else setWarrantyID(productWarranty.shopifyVariantId);
  };

  const dispatch = useVendorDispatch(product.vendor);
  const conditionDescription = CONDITIONS.find(
    (e) => e.title === t(tags.condition as string),
  )?.desc;
  const { store } = settings;

  // determine product category
  const productCategory = extractTags(product.tags).category || '';

  const isSmartwatch = productCategory === 'Smartwatches';
  const shouldShowSmartwatchColor = !(
    tags.watchBandColor &&
    tags.watchBandType &&
    tags.watchCaseColor &&
    tags.watchCaseMaterial
  );

  let variantCount = 1;

  if (tags.color) variantCount += 1;
  if (tags.storage) variantCount += 1;

  if (tags.color && isSmartwatch && !shouldShowSmartwatchColor)
    variantCount -= 1;

  const variantBoxCSS = `first:rounded-tl-md first:rounded-bl-md last:rounded-br-md last:rounded-tr-md text-center text-xs font-bold text-gray-700 border border-gray-200 flex justify-center items-center px-2 py-3 xxs:px-2 xs:px-3 xxs:py-4 lg:px-4 lg:py-4 box-border [&:not(:first-child)]:border-l-0 leading-none w-${
    variantCount === 1 ? 'full' : `1/${variantCount}`
  }`;

  const productSoldCount = useProductSoldCount(extractTags(product.tags).psku);
  const isBrandNew = condition === 'Brand New';

  const generateAltTag = () => {
    let altTag = product.title.split(' - ')[0];

    if (tags.storage) altTag += ` ${tags.storage}`;
    if (tags.color) altTag += ` in ${tags.color}`;
    if (tags.condition) altTag += ` in ${condition} condition`;

    return altTag;
  };

  // fetch delivery by via API
  const { zipcodeStateData, setZipcodeStateData } = useContext(AppCtx);
  const deliveryDate = useDeliveryDate({
    zipcode: zipcodeStateData?.zipcode,
    state: zipcodeStateData?.state,
    category: productCategory,
    vendor: product.vendor,
  });

  const [shippingData, setShippingData] = useState<ShippingData>({
    type: 'standard',
    value: 0,
    cutoffTime: deliveryDate?.standard.cutoffTime || '',
    cutoffTimezone: deliveryDate?.standard.cutoffTimezone || '',
  });

  const expressShippingOffer = useExpressShippingOffer({
    vendor: product.vendor,
    categoryPk: props.categoryPk,
    isEnabled:
      deliveryDate != null &&
      deliveryDate.express != null &&
      deliveryDate.express.minDeliveryDate != null &&
      deliveryDate.express.minDeliveryDate !== '',
  });

  const handleShippingTypeChanged = (data: ShippingData) => {
    setShippingData(data);
  };

  const isAUStore = store === 'reebelo-au';
  const isUSStore = store === 'reebelo-us';

  // Edit NON_TECH_CATEGORIES in @settings/configs/index.ts
  const isTechProduct = !NON_TECH_CATEGORIES.includes(productCategory);

  useEffect(() => {
    setShippingData({
      type: 'standard',
      value: 0,
      cutoffTime: deliveryDate?.standard.cutoffTime || '',
      cutoffTimezone: deliveryDate?.standard.cutoffTimezone || '',
    });
  }, [deliveryDate?.standard.cutoffTime]);

  const mostPopularProducts = [
    'iPhone 11',
    'iPhone 12',
    'iPhone 11 Pro',
    'iPhone X',
    'iPhone 12 Pro Max',
  ];

  const isDeal = extractIsDealFromTags(product.tags);
  const {
    reviews: { reviews: productReviews, summary: productReviewsSummary },
    reviewsInState,
    setReviewsInState,
  } = useProductReviews(psku);

  const router = useRouter();

  const [recentlyViewed, fetchingRecentlyViewed] = useRecentlyViewed({
    name: productTitle,
    image: product?.images.edges[0]?.node?.url,
    type: 'product',
    handle: (router.query.handle as string) ?? '',
    sku: variant.sku,
    tags: product.tags,
    addedAt: dayjs().format(),
    price,
    comparePrice,
    summary: productReviewsSummary,
    vendor: product.vendor,
  });

  const warrantyPrice = warrantyID != null ? productWarranty?.price : 0;
  const totalPrice = price + shippingData.value + (warrantyPrice ?? 0);

  useEffect(() => {
    const dealEndInSEC = offer?.dealEndDate
      ? dayjs(`${offer?.dealEndDate}T23:59:59`).diff(dayjs(), 'second')
      : 0;

    fetchDealViewsCount(isDeal, offer?.sku || '', dealEndInSEC);
  }, [router.query]);

  const specifications =
    props.productSpecifications.length > 0
      ? props.productSpecifications
      : generateOldProductSpecifications(
          replacePlusSymbol(product.descriptionHtml),
        );

  const vendorInfo = useVendorDetails(product.vendor);

  const streamLinedCondition = streamLineCondition(category, condition ?? '');

  const isTradeInEligible = checkIsTradeInEligible();

  useEffect(() => {
    const handleWarrantySelection = async () => {
      if (!isWarrantyEligible || !offer) return;

      const warranty = await selectWarranty(
        offer.price,
        RC_DURATION['24_MONTH'],
        category,
      );

      setProductWarranty(warranty);
    };

    handleWarrantySelection();
  }, []);

  return (
    <>
      <GoogleTopQualityBadge />
      <Head>
        <title>
          {isBrandNew && settings.store !== 'reebelo-us'
            ? t`New`
            : t`Refurbished`}{' '}
          {removeDefaultColor(replacePlusSymbol(product.title.trim()))}
        </title>
        <WebsiteSchema />
        <meta
          name="description"
          content={replacePlusSymbol(
            `${descriptionPrefix.length > 0 ? `${descriptionPrefix}, ` : ''}${
              product.description
            }`,
          )}
        />
        {isPaused && <meta name="robots" content="noindex"></meta>}
        <Microdata
          product={product}
          price={price}
          isOrganicAdRedirect={isOrganicAdRedirect}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <BackToTopButton />
      <div className="bg-white">
        <div className="lg:reebelo-container pt-0 lg:pt-10">
          <Breadcrumb
            breadcrumb={breadcrumb}
            productTitle={productTitle}
            className="mb-2.5 -mt-4 hidden lg:flex"
          />
          <ConditionPopup
            activeCondition={streamLinedCondition}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            category={productCategory}
            isOldPdp
          />
          <div className="flex flex-col gap-3 sm:gap-4 lg:flex-row lg:gap-6">
            <div className="relative w-full select-none bg-white p-5 text-center lg:flex-1 lg:p-0">
              <Breadcrumb
                breadcrumb={breadcrumb}
                productTitle={productTitle}
                className="mb-2.5 lg:hidden"
                isMobile
              />
              <div className="relative mb-2 flex justify-between gap-2 lg:hidden">
                <div className="flex-1 text-left text-xl text-gray-700">
                  {isDeal && (
                    <div className="mb-2 hidden w-12 sm:block lg:hidden">
                      <HotDealBadge block />
                    </div>
                  )}
                  <h2
                    className="text-base font-extrabold leading-6 xs:text-lg"
                    id="e2e-product-name-mobile"
                  >
                    {productTitle}
                  </h2>
                  <ReviewsStars
                    className="text-xs"
                    summary={productReviewsSummary}
                    scrollBy="reviews-slider"
                  />
                  <p className="text-sm">
                    <ProductSold totalProductSold={productSoldCount} />
                    <span className="inline-block rounded-full py-0 text-xs font-bold text-gray-700 xs:py-1 xs:text-xs">
                      {isAUStore &&
                      mostPopularProducts.includes(productTitle) ? (
                        <span>
                          <span className="relative top-[2px] mr-1 inline-flex w-[10px] items-center xs:w-auto">
                            <Image
                              src={heart}
                              alt={t`Most popular!`}
                              width={12}
                              height={14}
                              className="object-contain"
                            />
                          </span>
                          {t`Most popular!`}
                        </span>
                      ) : (
                        <span>
                          <span className="relative top-[2px] mr-1 inline-flex w-[10px] items-center xs:w-auto">
                            <Image
                              src={sellingFast}
                              alt={t`Selling Fast!`}
                              width={12}
                              height={14}
                              className="object-contain"
                            />
                          </span>
                          {t`Selling Fast!`}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                {/* Small Screen */}
                <div className="w-[90px] shrink-0 sm:hidden">
                  {offer != null && (
                    <Wishlist
                      offer={offer as SearchOffers}
                      reviews={productReviewsSummary}
                      oldPdp
                    />
                  )}
                </div>
              </div>
              <div className="sticky lg:top-[9rem]">
                <div className="rounded-md px-5 py-3 sm:px-8 sm:py-4 lg:border lg:px-6 lg:py-6">
                  <p className="hidden text-right text-sm lg:block">
                    <ProductSold totalProductSold={productSoldCount} />
                    <span className="inline-block text-xs font-bold text-gray-700 xs:text-xs">
                      {isAUStore &&
                      mostPopularProducts.includes(productTitle) ? (
                        <span>
                          <span className="relative top-[2px] mr-1 inline-flex w-[10px] items-center xs:w-auto">
                            <Image
                              src={heart}
                              alt={t`Most popular!`}
                              width={12}
                              height={14}
                              className="object-contain"
                            />
                          </span>
                          {t`Most popular!`}
                        </span>
                      ) : (
                        <span>
                          <span className="relative top-[2px] mr-1 inline-flex w-[10px] items-center xs:w-auto">
                            <Image
                              src={sellingFast}
                              alt={t`Selling Fast!`}
                              width={12}
                              height={14}
                              className="object-contain"
                            />
                          </span>
                          {t`Selling Fast!`}
                        </span>
                      )}
                    </span>
                  </p>
                  <ProductImageSlider
                    images={images}
                    defaultAltTag={generateAltTag()}
                  />
                  <div className="hidden sm:block">
                    <TrustpilotWidget
                      desktopAlign="center"
                      mobileAlign="center"
                      className="mb-2 mt-2 text-center text-xs xs:mt-4 xs:text-sm sm:mb-0 sm:mt-0 sm:text-base lg:mt-7"
                      starClassName="h-3 w-3 xs:h-4 xs:w-4"
                    />
                  </div>
                </div>
                <hr className="-mx-5 mt-3 lg:hidden" />
                <div className="px-3 pt-5 text-left lg:pb-5">
                  <Usps
                    deliveryDate={deliveryDate}
                    isOldPdp
                    productCondition={tags.condition ?? ''}
                    categoryWarrantyInfo={props.categoryWarrantyInfo}
                  />
                </div>
              </div>
            </div>
            <div
              className={cn({
                '-my-3 w-full border-t border-gray-200 px-5 text-center sm:hidden':
                  shouldShowPromoMessage(store, price),
                hidden: !shouldShowPromoMessage(store, price),
              })}
            >
              <PromoMessages
                price={price}
                type="old-pdp"
                category={category}
                sku={variant.sku}
              />
            </div>
            <div className="relative w-full border-t border-gray-200 px-5 py-2 sm:px-8 lg:flex-1 lg:border-0 lg:px-0 lg:py-0">
              <div className="relative z-20 mx-auto w-full rounded bg-white px-0 pt-4 sm:pt-5 lg:max-w-[480px] lg:rounded-none lg:bg-transparent lg:p-0 xl:max-w-[600px]">
                <div className="rounded-md border-0 border-gray-200 p-0 lg:border lg:px-7 lg:py-6">
                  <div className="mb-4 hidden items-start justify-between gap-2 lg:flex">
                    <div className="flex-1 text-xl text-gray-700">
                      {isDeal && (
                        <div className="mb-2 w-12">
                          <HotDealBadge block />
                        </div>
                      )}
                      <h1 className="font-extrabold" id="e2e-product-name">
                        {productTitle}
                      </h1>
                      <ReviewsStars
                        className="mb-4 text-xs"
                        summary={productReviewsSummary}
                        offset={100}
                        scrollBy="reviews-slider"
                      />
                    </div>
                    <MainPriceInfo
                      actualPrice={totalPrice}
                      comparePrice={comparePrice}
                    />
                  </div>
                  <div
                    className={cn('mb-4', {
                      hidden: !isTradeInEligible,
                    })}
                  >
                    <CompactTradeIn
                      setShowTradeInModal={setShowTradeInModal}
                      eligible={isTradeInEligible}
                    />
                  </div>
                  {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
                  <div className="flex flex-wrap rounded-md">
                    <div
                      className={cn('cursor-pointer', variantBoxCSS, {
                        '!w-4/12':
                          variantCount === 1 && shouldShowSmartwatchColor,
                      })}
                      onClick={() => setIsOpen(true)}
                    >
                      {streamLinedCondition} Condition
                      {/* eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values */}
                      <span className="-mt-[1px] ml-1 w-[12px] min-w-[11px] xxs:min-w-[12px]">
                        <Image
                          src={infoIconBlack}
                          alt={infoIconBlack}
                          className="w-full object-cover"
                        />
                      </span>
                    </div>
                    {variantCount === 1 && shouldShowSmartwatchColor && (
                      <div
                        className={cn(
                          '!w-8/12 !text-left !font-normal',
                          variantBoxCSS,
                        )}
                      >
                        {conditionDescription}
                      </div>
                    )}
                    {tags.storage && (
                      <div className={variantBoxCSS}>{tags.storage}</div>
                    )}
                    {shouldShowSmartwatchColor &&
                      tags.color &&
                      tags.color !== 'Default' && (
                        <div className={cn(variantBoxCSS)}>{tags.color}</div>
                      )}
                  </div>
                  {isSmartwatch &&
                    tags.watchBandColor &&
                    tags.watchBandType &&
                    tags.watchCaseColor &&
                    tags.watchCaseMaterial && (
                      <>
                        <div
                          // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
                          className="-mt-[1px] flex"
                        >
                          <div className={cn(variantBoxCSS)}>
                            {tags.watchCaseMaterial}{' '}
                            {!tags.watchCaseMaterial
                              .toLocaleLowerCase()
                              .includes('case') && 'Case'}
                          </div>
                          <div className={cn(variantBoxCSS)}>
                            {tags.watchCaseColor}{' '}
                            {!tags.watchCaseColor
                              .toLocaleLowerCase()
                              .includes('case') && 'Case'}
                          </div>
                        </div>
                        <div
                          // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
                          className="-mt-[1px] flex"
                        >
                          <div className={cn(variantBoxCSS)}>
                            {tags.watchBandType}{' '}
                            {!tags.watchBandType
                              .toLocaleLowerCase()
                              .includes('band') && 'Band'}
                          </div>
                          <div className={cn(variantBoxCSS)}>
                            {tags.watchBandColor}{' '}
                            {!tags.watchBandColor
                              .toLocaleLowerCase()
                              .includes('band') && 'Band'}
                          </div>
                        </div>
                      </>
                    )}
                  <div className="fixed bottom-0 left-0 z-50 flex w-full items-center justify-between gap-2 border-t border-gray-500 bg-white px-5 py-2 shadow-[0_-4px_9px_rgba(0,0,0,0.05)] sm:static sm:mt-4 sm:justify-start sm:border-t-0 sm:bg-transparent sm:p-0 sm:shadow-none">
                    <div className="sm:hidden">
                      <SecondaryPriceInfo
                        actualPrice={totalPrice}
                        comparePrice={comparePrice}
                      />
                    </div>
                    <div className="w-[200px] max-w-[450px] sm:flex-1">
                      <PurchaseLinks
                        offer={offer}
                        category={productCategory}
                        categoryPk={props.categoryPk}
                        cartButtonVariant="dark"
                        key={`${product.id}#${product.vendor}-purchase-links`}
                        warranty={warrantyID}
                        shippingData={shippingData}
                        deliverBy={{
                          min: deliveryDate?.standard.minDeliveryDate || '',
                          max: deliveryDate?.standard.maxDeliveryDate || '',
                        }}
                        subsidyTag={findSubsidyTag(product.tags)}
                        productId={ShopifyUtils.gqlIdToId(product.id)}
                        variantId={ShopifyUtils.gqlIdToId(variant.id)}
                        className="grid grid-cols-1 gap-3"
                        vendor={product.vendor}
                        productDetails={gtmProduct}
                        isOutOfStock={
                          product.productType?.toLowerCase() !== 'gift card' &&
                          product.totalInventory <= 0
                        }
                        comparePrice={comparePrice}
                      />
                    </div>
                    <div className="hidden w-[118px] sm:block">
                      {offer != null && (
                        <Wishlist
                          offer={offer as SearchOffers}
                          reviews={productReviewsSummary}
                          oldPdp
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={cn('hidden', {
                      'w-full sm:block': shouldShowPromoMessage(store, price),
                      '-mb-2 -ml-2 -mt-3':
                        isAUStore && shouldShowPromoMessage(store, price),
                      '-mb-2 -mt-2':
                        isUSStore && shouldShowPromoMessage(store, price),
                    })}
                  >
                    <PromoMessages
                      price={price}
                      type="old-pdp"
                      category={category}
                      sku={variant.sku}
                    />
                  </div>
                  {productWarranty && (
                    <div className="mt-4">
                      <AdditionalItem
                        Modal={WarrantySlideOver}
                        price={productWarranty.price}
                        onClick={handleWarrantyClick}
                        isActive={warrantyID !== null}
                        imageUrl={images[0].url}
                      />
                    </div>
                  )}
                  {/* Trade In */}
                  <div
                    className={cn({
                      'mt-4': isTradeInEligible,
                    })}
                  >
                    <TradeInSection
                      setShowModal={setShowTradeInModal}
                      eligible={isTradeInEligible}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <ShippingAndOtherOffer
                    deliveryDate={deliveryDate}
                    expressShippingOffer={expressShippingOffer}
                    dispatch={dispatch}
                    offer={{} as ReplacedProduct}
                    productName={productTitle}
                    vendor={product.vendor}
                    onShippingTypeChanged={handleShippingTypeChanged}
                    condition={tags.condition as string}
                    categoryPk={props.categoryPk}
                  />
                </div>
                <div className="mt-4 hidden bg-white sm:block">
                  <SpecificationsNew
                    specifications={specifications}
                    productName={productTitle}
                    comparePrice={comparePrice}
                    category={productCategory}
                    key={`${product.id}#${product.vendor}-specifications-desktop`}
                    warranty={warrantyID}
                    shippingData={shippingData}
                    deliverBy={{
                      min: deliveryDate?.standard.minDeliveryDate || '',
                      max: deliveryDate?.standard.maxDeliveryDate || '',
                    }}
                    subsidyTag={findSubsidyTag(product.tags)}
                    productId={ShopifyUtils.gqlIdToId(product.id)}
                    variantId={ShopifyUtils.gqlIdToId(variant.id)}
                    vendor={product.vendor}
                    productDetails={gtmProduct}
                    isOutOfStock={
                      product.productType?.toLowerCase() !== 'gift card' &&
                      product.totalInventory <= 0
                    }
                    price={price}
                    specs={tags}
                  />
                  {isTechProduct && (
                    <ProductInfoBox
                      comparePrice={comparePrice}
                      category={productCategory}
                      categoryPk={props.categoryPk}
                      key={`${product.id}#${product.vendor}-product-info-box-desktop`}
                      warranty={warrantyID}
                      shippingData={shippingData}
                      deliverBy={{
                        min: deliveryDate?.standard.minDeliveryDate || '',
                        max: deliveryDate?.standard.maxDeliveryDate || '',
                      }}
                      subsidyTag={findSubsidyTag(product.tags)}
                      productId={ShopifyUtils.gqlIdToId(product.id)}
                      variantId={ShopifyUtils.gqlIdToId(variant.id)}
                      vendor={product.vendor}
                      productDetails={gtmProduct}
                      isOutOfStock={
                        product.productType?.toLowerCase() !== 'gift card' &&
                        product.totalInventory <= 0
                      }
                      price={price}
                      tags={product.tags}
                    />
                  )}
                </div>
                <div className="mt-4 bg-white ">
                  <VendorInfoSection
                    setZipcodeStateData={setZipcodeStateData}
                    zipcodeStateData={zipcodeStateData}
                    dispatch={dispatch}
                    vendor={product.vendor}
                    vendorInfo={vendorInfo}
                    productDetails={{
                      source: 'Single Variant Product Page',
                      brand: gtmProduct.brand,
                      category: gtmProduct.category,
                      color: gtmProduct.color,
                      condition: gtmProduct.condition,
                      storage,
                      name: gtmProduct.name?.split(' - ')?.[0],
                      title: title.split(' - ')[0],
                      price: gtmProduct.price,
                      psku,
                      vendor: gtmProduct.vendor,
                      vendorSku: variant.sku,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="reebelo-container bg-white py-4 sm:hidden">
            <SpecificationsNew
              specifications={specifications}
              productName={productTitle}
              comparePrice={comparePrice}
              category={productCategory}
              key={`${product.id}#${product.vendor}-specifications-mobile`}
              warranty={warrantyID}
              shippingData={shippingData}
              deliverBy={{
                min: deliveryDate?.standard.minDeliveryDate || '',
                max: deliveryDate?.standard.maxDeliveryDate || '',
              }}
              subsidyTag={findSubsidyTag(product.tags)}
              productId={ShopifyUtils.gqlIdToId(product.id)}
              variantId={ShopifyUtils.gqlIdToId(variant.id)}
              vendor={product.vendor}
              productDetails={gtmProduct}
              isOutOfStock={
                product.productType?.toLowerCase() !== 'gift card' &&
                product.totalInventory <= 0
              }
              price={price}
              specs={tags}
            />
            {isTechProduct && (
              <ProductInfoBox
                comparePrice={comparePrice}
                category={productCategory}
                key={`${product.id}#${product.vendor}-product-info-box-mobile`}
                warranty={warrantyID}
                shippingData={shippingData}
                deliverBy={{
                  min: deliveryDate?.standard.minDeliveryDate || '',
                  max: deliveryDate?.standard.maxDeliveryDate || '',
                }}
                subsidyTag={findSubsidyTag(product.tags)}
                productId={ShopifyUtils.gqlIdToId(product.id)}
                variantId={ShopifyUtils.gqlIdToId(variant.id)}
                vendor={product.vendor}
                productDetails={gtmProduct}
                isOutOfStock={
                  product.productType?.toLowerCase() !== 'gift card' &&
                  product.totalInventory <= 0
                }
                price={price}
                tags={product.tags}
                categoryPk={props.categoryPk}
              />
            )}
          </div>
        </div>
        <div className="reebelo-container">
          <MoreProductInfo
            psku={product.id}
            description={productDescription}
            title="About this item"
            category={offer?.categories ? offer.categories[0] : ''}
          />
          {/*  You May Also Like */}
          <Section>
            <YouMayAlsoLike
              categoryParentName={categoryParentName}
              category={category ?? ''}
              brand={brand ?? ''}
              key={product.id}
            />
          </Section>
          {/*  More From Brand */}
          <MoreFromBrand
            category={productCategory ?? offer?.categories?.[0] ?? ''}
            brand={brand ?? offer?.brand ?? ''}
          />
          {/*  Hot Deals */}
          <HotDealSlider />
          {/*  Popular Categories */}
          <PopularCategories />
          {/*  Recently Viewed */}
          <RecentlyViewed
            recentlyViewed={recentlyViewed}
            fetching={fetchingRecentlyViewed}
          />
          <Section id="reviews-slider">
            <ProductReviews
              product={{
                collectionImageUrl: images[0]?.url,
                name: productTitle,
                psku,
              }}
              type="single-variant-reviews"
              reviews={reviewsInState?.reviews ?? productReviews}
              summary={productReviewsSummary}
              setReviewsInState={setReviewsInState}
              reviewsInState={reviewsInState}
            />
          </Section>
          <MoreProductInfo
            psku={product.id}
            description={productFAQ}
            title="Frequently Asked Questions"
          />
        </div>
      </div>
      <Section className="relative z-0 bg-teal-500 pt-8">
        <Marketplace view="product" category={category} />
      </Section>
      <div className="bg-white py-5 sm:hidden">
        <TrustpilotWidget
          desktopAlign="center"
          mobileAlign="center"
          className="text-center text-xs xs:text-sm sm:text-base"
          starClassName="h-3 w-3 xs:h-4 xs:w-4"
        />
      </div>
      <div className="reebelo-container">
        <Section>
          <CircularEconomy />
        </Section>
      </div>
      <TradeInModal
        showModal={showTradeInModal}
        hideModal={() => setShowTradeInModal(false)}
      />
    </>
  );
}
